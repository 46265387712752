import { sendRequest } from "../apiManager";

import { EventMinInfo } from "./event";

var futureEventCount = 0;

function getEvent(id, retrieveData) {
    sendRequest("event/" + id, "GET", "", (r) => {
        if(r.status == 200) {
            retrieveData(r.data);
        }else {
            window.open("/error/500", "_self");   
        }
    }, "");
}

function getNearestEvents(retrieveData, number) {

    sendRequest("event/nearest" + (number+1), "GET", "", (r) => {
        var eventList = [];
        var events = r.data.events;
        futureEventCount = events.length < (number+1) ? events.length : number+1;
        for(var i = 0; i < events.length; i++) {
            if(i>=number) break;
            var event = events[i];
            eventList.push(event);
        }
        retrieveData(eventList);
    }, "");
}

function getDatesBetween(retrieveData, from, to) {
    sendRequest("event/between/" + from + "/" + to, "GET", "", (r) => {
        retrieveData(r.data.dates);
    }, "");
}

function getEventsOnDay(retrieveData, day) {
    sendRequest("event/day/" + day, "GET", "", (r) => {
        if(r.status == 200) {
            var eventList = [];
            var events = r.data.events;
            for(var i = 0; i < events.length; i++) {
                var event = events[i];
                eventList.push(event);
            }
            retrieveData(eventList);
        }
    }, "");
}

function searchEvents(retrieveData, search) {
    sendRequest("event/search/" + search, "GET", "", (r) => {
        if(r.status == 200) {
            var eventList = [];
            var events = r.data.events;
            for(var i = 0; i < events.length; i++) {
                var event = events[i];
                eventList.push(event);
            }
            retrieveData(eventList);
        }
    }, "");
}

function getFutureEventCount() {
    return futureEventCount;
}

export {getEvent, getNearestEvents, getDatesBetween, getEventsOnDay, getFutureEventCount, searchEvents}
import React, {useEffect, useState} from "react";

import Calendar from 'react-calendar';

import "./calendar.css";
import { getDatesBetween } from "../api/events/eventManager";

function BALCalendar(props) {

    const [activeDate, setActiveDate] = useState(new Date());
    const [activeStartDate, setActiveStartDate] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [mark, setMark] = useState([]);

    useEffect(() => {
        if(!updated) {
            var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            var start = activeStartDate;
            if(start === null) {
                var st = (new Date().getMonth() + 1) + ".1" + "." + (new Date().getFullYear());
                start = new Date(st.replace(pattern,'$3-$2-$1'));
            }
            

            var st = (start.getMonth() + 2) + ".1" + "." + (start.getFullYear());
            if(start.getMonth() + 2 > 12) {
                st = "1.1" + "." + (start.getFullYear()+1);
            }
            var end = new Date(st.replace(pattern,'$3-$2-$1'));
            
            getDatesBetween(setMark, start.getTime()/1000, end.getTime()/1000);
            setUpdated(true);
        }
    });

    const selected = formatDate(activeDate);

    

    return <Calendar maxDetail="month" minDetail="month" defaultActiveStartDate={activeStartDate} onActiveStartDateChange={(r) => {setActiveStartDate(r.activeStartDate);setUpdated(false);}} onChange={(d) => {setActiveDate(d);props.onChange(d);}} value={activeDate} tileClassName={({ date, view }) => {
        const format = formatDate(date);
        for(var i = 0; i < mark.length; i++) {
            if(format === mark[i]) {
                if(format === selected) return "highlight selected";
                return "highlight";
            }
        }
        if(format === selected) return "selected";
      }}/>
}

function formatDate(date) {
    return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "/" + date.getFullYear();
}

export default BALCalendar;
import React, { useEffect, useRef, useState } from "react";

import ImageLink, { getImageUrl } from "./imageLink";

import "./imageRoundBox.css";

export default function ImageBox(props) {

    const [firstExecute, setFirstExecute] = useState(false);

    const boxEl = useRef();
    const [rect, setRect] = useState({
        "width": 0,
        "height": 0,
        "left": 0,
        "right": 0
    })

    useEffect(() => {
        setTimeout(() => {
            
        if(!props.fullscreen || firstExecute.current) return;
        var image = boxEl.current.children[0];
        const A_width = image.naturalWidth;
        const A_height = image.naturalHeight;
        const B_width = boxEl.current.offsetWidth;
        const B_height = boxEl.current.offsetHeight;
        const r = B_height / 2;
        const k = B_width - B_height;
        const l = A_width / A_height;
        const lb = k / B_height;
        var C_height = B_height;
        var C_width = l * C_height;
        if(lb < l) {
            const a = (-2*k+2*l*Math.sqrt(4*Math.pow(r, 2)-Math.pow(k, 2)+4*Math.pow(l, 2)*Math.pow(r, 2)))/(4+4*Math.pow(l,2));
            const b = Math.sqrt(Math.pow(r, 2) - Math.pow(a, 2));
            C_height = b * 2;
            C_width = l * C_height;
        }
        const C_left = (B_width - C_width) / 2;
        const C_top = (B_height - C_height) / 2;
        setRect({
            "width": C_width,
            "height": C_height,
            "left": C_left,
            "top": C_top
        });
        window.addEventListener("resize", () => {
            setFirstExecute(false);
        })
        setFirstExecute(true);
        }, 100);
    });

    if(props.fullscreen) {
        return <div alt={props.alt ? props.alt : ""} className="image-box" ref={boxEl}>
            <ImageLink link={props.image} className="blur"/>
            <ImageLink link={props.image} style={{
                position: "absolute",
                width: rect.width,
                height: rect.height,
                marginTop: rect.top,
                marginLeft: rect.left
            }}/>
        </div>
    }

    return <div className="image-box" style={{
        "backgroundImage": "url(" + getImageUrl(props.image) + ")"
    }}>
    </div>;

}
import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getArticle } from "../api/article/articleManager";
import Loader from "../components/loader";
import ImageBox from "../components/imageRoundBox";

import "./article.css";

import ClockIcon from "../assets/icons/clock";
import { formatDateFullMonth } from "../utils/dateUtils";
import Footer from "../components/footer";

function ArticlePage(props) {
    
    const {id} = useParams("id");

    const navigate = useNavigate();

    const [articleData, setArticleData] = useState();

    useEffect(() => {
        if(articleData == undefined) {
            getArticle(id, (data) => {
                setTimeout(() => {
                    setArticleData(data);
                }, 600);
            });
        }
    });

    if(articleData == undefined) {
        return <Loader/>;
    }

    return <>
        <div className="page" id="article-page">

<p className="grey navinfo"><a onClick={() => {
    navigate("/articles");
    }}>Aktuelles</a> / {articleData.title}</p>    

    <div className="section">
        <h1>{articleData.title}</h1>
        <h2>{articleData.subtitle}</h2>

        <div className="infos">
            <div className="info">
                <ClockIcon/><p>{formatDateFullMonth(new Date(articleData.time))}</p>
            </div>

            <div className="info">
                <ClockIcon/><p>{articleData.author}</p>
            </div>
        </div>

        <div className="content">
            {articleData.sections.map((s) => {
                var text = s.content, images = s.images;
                var type = "";
                if(text.length > 0) {
                    if(images.length == 0) {
                        type = "text-only";
                    }else {
                        type = "img" + images.length;
                    }
                }else if(images.length != 0) {
                    type = "img-only " + "img" + images.length;
                }else {
                    console.log("invalid section");
                    return <></>;
                }
                return <div className={"passage " + type + (s.attributes.alignImages == "right" ? " right-img" : "")}>
                    {s.content.length > 0 ? <div className="text">{s.content.split("\n").map(e => {
                        return <p>{e}</p>
                    })}</div> : <></>}
                    {s.images.map((img, i) => {
                        var attributes = s.imageAttributes.split(";")[i].split(",");
                        return <ImageBox alt={attributes[1]} image={img.account + "/" + img.uuid} fullscreen={attributes[0] == "true"}/>;
                    })}
                </div>;
            })}
        </div>
    </div>
</div>
<Footer/>
    </>;
}

export default ArticlePage;
import logo from './logo.svg';
import './App.css';
import ArticlesPage from './pages/articles';
import ArticlePage from './pages/article';
import Header from './components/header';

import {Route, Routes, HashRouter, Navigate, BrowserRouter} from "react-router-dom";

import "./style/fonts/filsonpro.css";


import "./style/colors.css";
import "./style/global.css";
import ClubPage from './pages/club';
import ClubListPage from './pages/clubs';
import SchedulePage from './pages/schedule';
import ErrorPage from './pages/error';
import SearchPage from './pages/search';
import Footer from './components/footer';
import EventPage from './pages/event';
import HomePage from './pages/home';
import AiderPage from './pages/aider';
import AiderInfoPage from './pages/aiderInfo';
import NewsletterLogout from './pages/newsletterLogout';
import ImprintPage from './pages/imprint';
import PrivacyPage from './pages/privacy';
import ContactPage from './pages/contact';


function App() {
  return (
    <>
      <BrowserRouter>
              <Routes>
                  <Route path="aider" element={<><Header active={4}/><AiderPage/></>} />
                  <Route path="aider/:id" element={<><Header/><AiderInfoPage/></>} />
                  <Route path="articles" element={<><Header active={1}/><ArticlesPage/></>} />
                  <Route path="article/:id" element={<><Header/><ArticlePage/></>}/>
                  <Route path="clubs" element={<><Header active={3}/><ClubListPage/></>} />
                  <Route path="clubs/:search" element={<><Header active={3}/><ClubPage/></>}/>
                  <Route path="club/:id" element={<><Header/><ClubPage/></>}/>
                  <Route path="schedule" element={<><Header active={2}/><SchedulePage/></>}/>
                  <Route path="event/:id" element={<><Header/><EventPage/></>}/>
                  <Route path="search" element={<><Header/><SearchPage/></>}/>
                  <Route path="error/:code" element={<><Header/><ErrorPage/></>}/>
                  <Route path="imprint" element={<><Header/><ImprintPage/></>}/>
                  <Route path="privacy" element={<><Header/><PrivacyPage/></>}/>
                  <Route path="contact" element={<><Header/><ContactPage/></>}/>
                  <Route path="*" element={<Navigate to="/error/404"/>}/>
                  <Route path="home" element={<><Header active={0}/><HomePage/></>}/>
                  <Route path="" element={<Navigate to="/home"/>}/>
                  <Route path="newsletter/logout/:type/:email/:key" element={<><Header/><NewsletterLogout/></>}/>
              </Routes>
          </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";

import "./multipleBox.css";

function MultipleBox(props) {

    var i = 0;

    return <div className="multiple-box">
        {props.children.map(r => {
            i += 1;
            if(i < props.children.length) {
                return <>{r}<hr/></>;
            }
            return r;
        })}
    </div>;
}

export default MultipleBox;
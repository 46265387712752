const weekdays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

function getWeekday(date) {
    return weekdays[date.getDay()];
}

function getMonthName(date) {
    return months[date.getMonth()];
}

function formatDateWithWeekday(date) {
    return getWeekday(date) + " " + formatDate(date);
}

function formatDate(date) {
    return addZero(date.getDate()) + "/" + addZero(date.getMonth() + 1) + "/" + date.getFullYear();
}

function formatDateFullMonth(date) {
    return addZero(date.getDate()) + ". " + getMonthName(date) + " " + date.getFullYear();
}

function formatDateWithDots(date) {
    return addZero(date.getDate()) + "." + addZero(date.getMonth()+1) + "." + date.getFullYear();
}

function formatClocktime(date1, date2) {
    console.log(date1);
    return addZero(date1.getHours()) + ":" + addZero(date1.getMinutes()) + " - " + addZero(date2.getHours()) + ":" + addZero(date2.getMinutes()) + (date1.getDate() != date2.getDate() ? " (+ 1Tag)" : "");
}

function addZero(number) {
    return number < 9 ? "0" + number : number;
}

function formatOpenWeekdays(value) {
    var output = "";
    var current = "";
    var firstDay = "";
    var lastDay = "";
    var arr = value.split("\n");
    arr[arr.length] = "";
    for(var i = 1 ; i < arr.length; i++) {
        var el = arr[i].split(" ");
        if(el[1] == "") continue;
        if(el[1] == current) {
            if(firstDay == "") {
                firstDay = el[0];
                current = el[1];
            }else {
                lastDay = el[0];
            }
            continue;
        }else if(firstDay != "" && lastDay != "") {
            output += firstDay + "-" + lastDay + ": " + current + "\n";
        }else {
            var val = firstDay + ": " + current + "\n";
            if(val.length > 3) {
                output += val;

            }
        }
        firstDay = "";
        current = "";
        lastDay = "";
        current = el[1];
        firstDay = el[0];
    }
    return output;
}

export {formatOpenWeekdays, formatClocktime, formatDate, formatDateWithWeekday, getWeekday, getMonthName, formatDateFullMonth, formatDateWithDots}